import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_SIGNUP_FAIL,
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
} from "../constants/authConstants";
import { supabase } from "../../config/supabase";


export const userSignup = (name, email, password) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_SIGNUP_REQUEST });

      //Check if User
      const { data: isUser, error: isUserError } = await supabase
        .from("users")
        .select("*")
        .eq("email", email);

      if (isUserError) {
        dispatch({ type: USER_SIGNUP_FAIL, payload: [isUserError] });
        return;
      }
      if (isUser.length !== 0) {
        dispatch({
          type: USER_SIGNUP_FAIL,
          payload: [{ message: "User already exists" }],
        });
        return;
      }

      //Signup
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
      });
      if (error) {
        dispatch({
          type: USER_SIGNUP_FAIL,
          payload: [error],
        });
        return;
      }

      //Store Data into DB
      const { error: DBError } = await supabase.from("users").insert([
        {
          id: data.user.id,
          name,
          email,
          role: "owner",
          isCustomer: false,
          customer_role: "free",
        },
      ]);
      if (DBError) {
        dispatch({
          type: USER_SIGNUP_FAIL,
          payload: [DBError],
        });
        return;
      }

      dispatch({ type: USER_SIGNUP_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({
        type: USER_SIGNUP_FAIL,
        payload: [error],
      });
      return;
    }
  };
};

export const userLogin = (email, password) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_LOGIN_REQUEST });

      //Check if User
      const { data: isUser, error: isUserError } = await supabase
        .from("users")
        .select("*")
        .eq("email", email);

      if (isUserError) {
        dispatch({ type: USER_LOGIN_FAIL, payload: [isUserError] });
        return;
      }
      if (isUser.length === 0) {
        dispatch({
          type: USER_LOGIN_FAIL,
          payload: [{ message: "User not found" }],
        });
        return;
      }

      //Check if User is removed
      if (isUser[0].isActive === false) {
        dispatch({
          type: USER_LOGIN_FAIL,
          payload: [
            { message: "Account removed. Contact Support for details" },
          ],
        });
        return;
      }

      //Login
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) {
        dispatch({
          type: USER_LOGIN_FAIL,
          payload: [error],
        });
        return;
      }

      dispatch({ type: USER_LOGIN_SUCCESS, payload: data.user.id });
    } catch (error) {
      console.log(error);
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: [error],
      });
      return;
    }
  };
};
