// // Individual exports
const PUBLIC_CTI_USER_KEY = "https://api.climatetrackerinitiative.org";

// const PUBLIC_CTI_USER_KEY = "http://localhost:8997";

const BASE_URL = "https://cti-backend-api-864a20d2b82c.herokuapp.com";

// Uncomment the following line for local development
// const BASE_URL = "http://localhost:5000";

module.exports.PUBLIC_CTI_USER_KEY = PUBLIC_CTI_USER_KEY;
module.exports.BASE_URL = BASE_URL;
